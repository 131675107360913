import NotFound from '@/app/not-found';
import React, { Component } from 'react';
class ErrorBoundary extends Component<any> {
  state = {
    hasError: false
  };
  static getDerivedStateFromError(error: any) {
    return {
      hasError: true
    };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // Log the error to an error reporting service
    console.error(error, errorInfo, "error found");
  }
  render() {
    if (this.state.hasError) {
      // You can render a fallback UI here
      return <NotFound data-sentry-element="NotFound" data-sentry-component="ErrorBoundary" data-sentry-source-file="index.tsx" />;
    }
    return this?.props?.children;
  }
}
export default ErrorBoundary;