"use client";

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { routeVariables } from '../routestrings';
const useViewType = (threshold = 800) => {
  const pathname = usePathname();
  const MOBILE_SCREEN_THRESHOLD = pathname.includes(routeVariables.COURSEDETAIL) ? 1000 : threshold; // in pixels

  const [isMobile, setIsMobile] = useState(typeof window !== 'undefined' ? window.innerWidth < MOBILE_SCREEN_THRESHOLD : false);
  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileViewport = window.innerWidth < MOBILE_SCREEN_THRESHOLD;
      if (isMobileViewport !== isMobile) {
        setIsMobile(isMobileViewport);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [isMobile]);
  return isMobile;
};
export default useViewType;