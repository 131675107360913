import { TypoGraphyProps } from '@/interfaces';
import React from 'react';
export const variants = {
  'Desktop/Body 1/M | 16 |160': 'D_B_1_M_16_160',
  'Desktop/Body 1/M | 16 |120': 'D_B_1_M_16_120',
  'Desktop/Body 1/R | 16 |120': 'D_B_1_R_16_120',
  'Desktop/Body 1/R | 16 |160': 'D_B_1_R_16_160',
  'Desktop/Body 2/R | 14 |120': 'D_B_2_R_14_120',
  'Desktop/Body 2/R | 14 | 160': 'D_B_2_R_14_160',
  'Desktop/Heading 1/M | 20 |120': 'D_H_1_M_20_120',
  'Desktop/Heading 1/S | 20 |120': 'D_H_1_S_20_120',
  'Desktop/Heading 3': 'D_H_3',
  'Desktop/Heading 2': 'D_H_2',
  'Desktop/Heading 1': 'D_H_1',
  'Desktop/Caption/14 | 120 | | 120 | 10% Letter Spacing': 'D_C_14_120_120_10_L_S',
  'Condensed/Desktop/Heading 1 | M | 32 | 140': 'C_D_H_1_M_32_140',
  'Condensed/Desktop/Heading 2 | M | 24 | 120': 'C_D_H_2_M_24_120',
  'LP/Paragraph/Paragraph Small': 'LP_P_P_S',
  'LP/Pre-header/Pre-header': 'LP_Pre_Pre',
  'Desktop/Body 2/M | 14 |120': 'D_B_2_M_14_120',
  'Desktop/Caption/R | 12 |120': 'D_C_R_12_120',
  'Mobile/Body 2/M | 14 | 120': 'M_B_2_M_14_120',
  'Mobile/Body 1/M | 16 | 160': 'M_B_1_M_16_160',
  'Mobile/Body 2/M | 14 | 160': 'M_B_2_M_14_160',
  'Mobile/Body 2/R | 14 | 120': 'M_B_2_R_14_120',
  'Mobile/Body 2/R | 14 | 160': 'M_B_2_R_14_160',
  'Condensed/Mobile/Heading 1 | M | 24 | 140': 'C_M_H_1_M_24_140',
  'Condensed/Mobile/Heading 2 | M | 20 | 120': 'C_M_H_2_M_20_120',
  'Mobile/Body 1/M | 16 | 120': 'M_B_1_M_16_120',
  'Mobile/Heading 1/S | 20 | 160': 'M_H_1_S_20_160',
  'Label/Industry Card Link': 'L_I_C_L',
  'Label/Card - Regular': 'L_C_R',
  'Label/Card - Large': 'L_C_L',
  'Label/Program Tag - Regular': 'L_P_T_R',
  'Body 1/Medium/16|120%': 'B_1_M_16_120',
  'Mobile/Caption/R | 12 | 120': 'M_C_R_12_120',
  'Mobile/Caption/R | 12 | 120 | 6%': 'M_C_R_12_120_6',
  'Mobile/Caption/R | 12 | 160': 'M_C_R_12_160',
  'Mobile/Heading 1/S | 20 | 120': 'M_H_1_S_20_120',
  'Mobile/BreadCrumb': 'M_Bread_Crumb',
  'Mobile/BreadCrumb 2': 'M_Bread_Crumb_2',
  'Mobile/Heading': 'M_Heading',
  'Mobile/Heading/1': 'M_Heading_1',
  'Mobile/Body 1': 'M_Body_1',
  'Mobile/Body 2': 'M_Body_2',
  'Desktop/Body 1': 'D_Body_1',
  'Desktop/Body 2': 'D_Body_2',
  'Desktop/Body 3': 'D_Body_3',
  'Mobile/Caption': 'M_Caption',
  'Desktop/Caption': 'D_Caption',
  'Desktop/Thumbnail | 16 | 140': 'Thumnbail_16_140',
  'Mobile/Tags': 'M_Tags',
  'Newsletter': 'newsletter',
  'Top 1': 'top_1',
  // homepage variants
  'Display 1 M | 70 | 130': 'Display_1_M_70_130',
  'Display 2 M | 40 | 130': 'Display_2_M_40_130',
  'Display 3 M | 36 | 130': 'Display_3_M_36_130',
  'Display 4 M | 34 | 120': 'Display_4_M_34_120',
  'Display 5 M | 32 | 120': 'Display_5_M_32_120',
  'Heading 1 M | 24 | 130': 'Heading_1_M_24_130',
  'Heading 2 M | 20 | 120': 'Heading_2_M_20_120',
  'Heading 1 M | 20 | 120': 'Heading_1_M_20_120',
  'Body 1 M | 16 | 130': 'Body_1_M_16_130',
  'Body 1 M | 16 | 120': 'Body_1_M_16_120',
  'Body 1 R | 16 | 160': 'Body_1_R_16_160',
  'Body 1 R | 16 | 120': 'Body_1_R_16_120',
  'Body 2 M | 14 | 140': 'Body_2_M_14_140',
  'Body 2 M | 14 | 120': 'Body_2_M_14_120',
  'Body 2 R | 14 | 160': 'Body_2_R_14_160',
  'Body 2 R | 14 | 140': 'Body_2_R_14_140',
  'Body 2 R | 14 | 135': 'Body_2_R_14_135',
  'Body 2 R | 14 | 120': 'Body_2_R_14_120',
  'Body 3 R | 12 | 120': 'Body_3_R_12_120',
  'Caption R | 12 | 120': 'Caption_R_12_120',
  'Desktop/Body 1/M | 16 |130': 'Desktop_Body_1_M_16_130',
  'Mobile/Display/M | 28 | 130': 'Mobile_Display_M_28_130',
  'Display M | 28 | 135': 'Display_M_28_135',
  'Body 2 R | 14 | 120 - ALL CAPS': 'Body_2_R_14_120_ALLCAPS',
  'Body 1 M | 16 | 120 - ALL CAPS': 'Body_1_M_16_120_ALLCAPS',
  'Caption R | 12 | 120 - ALL CAPS': 'Caption_R_12_120_ALL_CAPS'
};

// export const HomePageVariants = {
//     'Display 1 M | 70 | 130': 'Display_1_M_70_130',
//     'Display 2 M | 40 | 130': 'Display_2_M_40_130',
//     'Display 3 M | 36 | 130': 'Display_3_M_36_130',
//     'Display 4 M | 34 | 120': 'Display_4_M_34_120',
//     'Display 5 M | 32 | 120': 'Display_5_M_32_120',
//     'Heading 1 M | 24 | 130': 'Heading_1_M_24_130',
//     'Heading 2 M | 20 | 120': 'Heading_2_M_20_120',
//     'Body 1 M | 16 | 130': 'Body_1_M_16_130',
//     'Body 1 M | 16 | 120': 'Body_1_M_16_120',
//     'Body 1 R | 16 | 160': 'Body_1_R_16_160',
//     'Body 2 M | 14 | 140': 'Body_2_M_14_140',
//     'Body 2 M | 14 | 120': 'Body_2_M_14_120',
//     'Body 2 R | 14 | 160': 'Body_2_R_14_160',
//     'Body 2 R | 14 | 140': 'Body_2_R_14_140',
//     'Body 2 R | 14 | 135': 'Body_2_R_14_135',
//     'Body 2 R | 14 | 120': 'Body_2_R_14_120',
//     'Caption R | 12 | 120': 'Caption_R_12_120',

//     'Display M | 28 | 135': 'Display_M_28_135',

//     'Body 2 R | 14 | 120 - ALL CAPS': 'Body_2_R_14_120_ALLCAPS',
//     'Caption R | 12 | 120 - ALL CAPS': 'Caption_R_12_120_ALL_CAPS',
// }

export const tagvariants: any = {
  'h1': (props: TypoGraphyProps) => <h1 className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles}>{props?.text}</h1>,
  'h2': (props: TypoGraphyProps) => <h2 className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles}>{props?.text}</h2>,
  'h3': (props: TypoGraphyProps) => <h3 className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles}>{props?.text}</h3>,
  'h4': (props: TypoGraphyProps) => <h4 className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles}>{props.text}</h4>,
  'p': (props: TypoGraphyProps) => <p className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles}>{props?.text}</p>,
  'strong': (props: TypoGraphyProps) => <strong className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles}>{props?.text}</strong>,
  'a': (props: TypoGraphyProps) => <a href={props.href} target='_self' title={props?.title} className={variants[props.variant] + (props.className ? ' ' + props.className : '')} style={props.styles} onClick={e => {
    if (!props?.isArticle) e.preventDefault();
  }}>{props?.text}</a>
};
export type tagVariants = keyof typeof tagvariants;
export type Variants = keyof typeof variants;

// export type hometagVariants = keyof typeof HomePageVariants;

const TypoGraphy = (props: TypoGraphyProps) => {
  const textStyles: any = props?.styles ? {
    ...props.styles
  } : {};
  textStyles['color'] = props?.color ? props.color : 'black';
  return <div onClick={() => {
    if (!props?.isArticle && props.clickHandler) {
      props?.clickHandler();
    }
  }} onMouseEnter={props?.onMouseEnter} onMouseLeave={props?.onMouseLeave} data-sentry-component="TypoGraphy" data-sentry-source-file="index.tsx">
            {tagvariants[props.tagName ? props.tagName : 'p']({
      ...props,
      styles: textStyles
    })}
        </div>;
};
export default TypoGraphy;