"use client";

import TypoGraphy from "@/typography";
import Button from "@/uicomponents/Button";
import useViewType from "@/utils/Hooks/useViewType";
import { usePathname, useRouter } from "next/navigation";
import { IBM_Plex_Sans, IBM_Plex_Sans_Condensed } from "next/font/google";
const metadata = {
  title: "GrowthSchool - Become the top 1%",
  description: "Whether it is Product, Growth, Design, Management, Tech & Data, Business, GrowthSchool is the place to learn from top experts in the field."
};
export const ibm_plex_sans_init = IBM_Plex_Sans({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "700"],
  variable: "--font-ibm-plex-sans"
});
export const ibm_plax_sans_condensed = IBM_Plex_Sans_Condensed({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600", "700"],
  variable: "--font-ibm-plex-sans-condensed"
});
export default function NotFound() {
  const isMobile = useViewType();
  const {
    push
  } = useRouter();
  const path = usePathname();
  const redirectUrl: any = {
    "/apply-as-mentor": "https://home.growthschool.io/apply-as-mentor",
    "/b2b": "https://home.growthschool.io/b2b",
    "/learners-center": "https://home.growthschool.io/learners-center",
    "/mentors": "https://home.growthschool.io/mentors",
    "/newsletter-vault": "https://home.growthschool.io/newsletter-vault",
    "/wall-of-love": "https://home.growthschool.io/wall-of-love",
    "/web3-application": "https://home.growthschool.io/web3-application",
    "/linkedin-workshop": "https://home.growthschool.io/linkedin-workshop",
    "/lifetime-deal": "https://home.growthschool.io/lifetime-deal",
    "/careers": "https://home.growthschool.io/careers",
    "/lc/marketing": "https://home.growthschool.io/lc/marketing"
  };
  if (path.includes("/ai") || path.includes("/ai/")) {
    push("https://applications.growthschool.io/292/1207?utm_source=OTT&utm_medium=Hotstar&utm_campaign=WCC2024&utm_adset=topcities&utm_content=aitakingover&utm_term=20062024");
    return;
  }
  if (redirectUrl[path] || path.includes("/newsletter/") || path.includes("/articles/") || path.includes("/programs/") || path.includes("/mentors/") || path.includes("/categories/") || path.includes("/author/") || path.includes("/topics/") || path.includes("/crash-courses/") || path.includes("/industry/")) {
    push(`https://home.growthschool.io${path}`);
    return;
  }
  return <>
      <div>
        <div>
          <meta name="robots" content="noindex" data-sentry-element="meta" data-sentry-source-file="not-found.tsx"></meta>
          <meta name="googlebot" content="noindex" data-sentry-element="meta" data-sentry-source-file="not-found.tsx"></meta>
          <meta name="title" content={metadata.title} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <meta name="description" content={metadata.description} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <meta property="og:title" content={metadata.title} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <meta property="og:description" content={metadata.description} data-sentry-element="meta" data-sentry-source-file="not-found.tsx" />
          <title>{metadata.title}</title>
          <link href="/favicon-new.svg" rel="icon" type="image/x-icon" />
        </div>
        <div className={`${ibm_plex_sans_init.variable} ${ibm_plax_sans_condensed.variable}`}>
          <div className="main_container">
            <div className="page_not_found_wrapper" style={{
            minHeight: "80vh",
            paddingTop: isMobile ? "89px" : "112px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }}>
              <div className="not_found_content_wrapper" style={{
              display: "flex",
              flexDirection: "column",
              gap: isMobile ? "12px" : "16px",
              alignItems: "center"
            }}>
                <TypoGraphy text="404 - Page not found" variant={isMobile ? "Mobile/Heading" : "Desktop/Heading 2"} tagName="h1" data-sentry-element="TypoGraphy" data-sentry-source-file="not-found.tsx" />
                <TypoGraphy text="Let's redirect your passion for learning back to our course offerings" variant={isMobile ? "Mobile/Body 2/R | 14 | 120" : "Desktop/Body 1"} styles={{
                textAlign: "center"
              }} data-sentry-element="TypoGraphy" data-sentry-source-file="not-found.tsx" />
                <div className="go_back_wrapper">
                  <Button text="Go Back" variant="NormalVariant" textVariant={isMobile ? "Mobile/Body 2/M | 14 | 120" : "Desktop/Body 1/M | 16 |120"} textColor="white" backgroundColor="black" clickHandler={() => push("/")} data-sentry-element="Button" data-sentry-source-file="not-found.tsx" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>;
}