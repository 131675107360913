"use client";

import { AppContext } from "@/contexts/appcontext";
import ErrorBoundary from "@/errorboundary";
import { GoogleAnalytics } from "@next/third-parties/google";
import { usePathname } from "next/navigation";
import { useContext } from "react";
export default function InLayout({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const pathname = usePathname();
  const {
    ogImage,
    title,
    description
  } = useContext<any>(AppContext);
  return <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-component="InLayout" data-sentry-source-file="layout.tsx">
      <GoogleAnalytics gaId={process.env.NEXT_PUBLIC_GA_ID ?? ""} data-sentry-element="GoogleAnalytics" data-sentry-source-file="layout.tsx" />
      <div>
        <meta name="title" content={title} data-sentry-element="meta" data-sentry-source-file="layout.tsx" />
        <meta name="description" content={description} data-sentry-element="meta" data-sentry-source-file="layout.tsx" />
        <meta property="og:title" content={title} data-sentry-element="meta" data-sentry-source-file="layout.tsx" />
        <meta property="og:description" content={description} data-sentry-element="meta" data-sentry-source-file="layout.tsx" />
        <meta property="og:image" content={ogImage} data-sentry-element="meta" data-sentry-source-file="layout.tsx" />        
        <title>{"GrowthSchool - Become the top 1%"}</title>

        <link href="/favicon-new.svg" rel="icon" type="image/x-icon" />
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_APP_URL}${pathname}`} />
        <link rel="preconnect" href="https://s3.ap-south-1.amazonaws.com/assets.growthschool.io" crossOrigin="anonymous" />
        <link rel="dns-prefetch" href="https://s3.ap-south-1.amazonaws.com/assets.growthschool.io" />
      </div>

      {children}
    </ErrorBoundary>;
}