import { ButtonProps } from '@/interfaces';
import React from 'react';
import './index.scss';
import TypoGraphy from '@/typography';
import Image from 'next/image';
import { gumletLoader } from '@/utils/gumlet';
const variants = {
  'NavbarVariant': 'navbar_button',
  'FooterVariant': 'footer_button',
  'NormalVariant': 'normal_button',
  'ArticleVariant': 'article_button',
  'HomePageVariant': 'homepage_button'
};
export type ButtonVariants = keyof typeof variants;
const Button = ({
  text,
  styles,
  image,
  backgroundColor,
  borderColor,
  textVariant,
  variant,
  textColor,
  clickHandler,
  disabled,
  tagName,
  imageWidth,
  imageHeight
}: ButtonProps) => {
  const buttonStyle: any = styles ? {
    ...styles
  } : {};
  if (backgroundColor) buttonStyle['backgroundColor'] = backgroundColor;
  if (borderColor) buttonStyle['borderColor'] = borderColor;
  if (tagName && tagName === 'a') {
    return <a className="button_wrapper">
            <button className={variants[variant]} disabled={disabled} style={buttonStyle} onClick={clickHandler}> <TypoGraphy text={text} variant={textVariant} color={textColor} /> {image && <Image loader={gumletLoader} width={imageWidth} height={imageHeight} src={image} alt='button_img' loading='lazy' />}</button>
        </a>;
  }
  return <div className="button_wrapper" data-sentry-component="Button" data-sentry-source-file="index.tsx">
            <button className={variants[variant] + (disabled ? " disabled_button" : "")} disabled={disabled} style={buttonStyle} onClick={clickHandler}> <TypoGraphy text={text} variant={textVariant} color={textColor} data-sentry-element="TypoGraphy" data-sentry-source-file="index.tsx" /> {image && <Image loader={gumletLoader} width={imageWidth} height={imageHeight} src={image} alt='button_img' loading='lazy' />}</button>
        </div>;
};
export default Button;